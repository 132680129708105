import { createApp } from 'vue';
import './registerServiceWorker';
import PrimeVue from 'primevue/config';
// eslint-disable-next-line import/no-extraneous-dependencies
import Aura from '@primevue/themes/aura';
import ToastService from 'primevue/toastservice';
import router from './router';
import store from './store';
import App from './App.vue';

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(PrimeVue, {
    theme: {
      preset: Aura,
    },
  })
  .use(ToastService);

app.mount('#app');
