<template>
  <nav class="navbar">
    <div class="nav-container">
      <div class="logo">
        <RouterLink to="/">
          <img style="width: 50%" class="logo-img" src="@/assets/server-icon.png" alt="Logo" />
        </RouterLink>
      </div>
      <ul class="nav-links">
        <li><RouterLink to="/" active-class="active-link">Home</RouterLink></li>
        <li><RouterLink to="/apply" active-class="active-link">Apply</RouterLink></li>
        <li><RouterLink to="/news" active-class="active-link">News</RouterLink></li>
        <li><a target="_blank" href="https://discord.gg/genempire">Discord</a></li>
        <li><a target="_blank" href="https://store.genempire.net" active-class="active-link">Shop</a></li>
        <li class="profile-link">
          <RouterLink v-if="!isLoggedIn" to="/login" active-class="active-link">Login</RouterLink>
          <RouterLink v-else to="/profile" active-class="active-link">Profile</RouterLink>
        </li>
      </ul>
      <div class="burger" @keydown.enter="toggleNav" @click="toggleNav">
        <span :class="{ 'active': navActive }"></span>
        <span :class="{ 'active': navActive }"></span>
        <span :class="{ 'active': navActive }"></span>
      </div>
    </div>
    <!-- Mobile Navigation -->
    <ul class="nav-links-mobile" v-if="navActive">
      <li><RouterLink to="/" @click="toggleNav"
                      active-class="active-link">Home</RouterLink></li>
      <li><RouterLink to="/apply" @click="toggleNav"
                      active-class="active-link">Apply</RouterLink></li>
      <li><RouterLink to="/news" @click="toggleNav"
                      active-class="active-link">News</RouterLink></li>
      <li class="profile-link">
        <RouterLink v-if="!isLoggedIn" to="/login"
                    @click="toggleNav" active-class="active-link">Login</RouterLink>
        <RouterLink v-else to="/profile" @click="toggleNav"
                    active-class="active-link">Profile</RouterLink>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref } from 'vue';
import { RouterLink } from 'vue-router';

const navActive = ref(false);
const isLoggedIn = ref(false);

const toggleNav = () => {
  navActive.value = !navActive.value;
};
</script>

<style scoped>
.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  background: rgba(44, 62, 80, 0.9);
  font-family: 'rosemero', sans-serif;
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  width: 120px;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
}

.nav-links li {
  margin-left: 1.5rem;
}

.nav-links li a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.nav-links li a:hover,
.nav-links li .active-link {
  color: #fff;
  background-color: #3498db;
}

.profile-link a:hover,
.profile-link .active-link {
  background-color: #2980b9;
}

.burger {
  display: none;
  cursor: pointer;
  flex-direction: column;
}

.burger span {
  height: 3px;
  width: 25px;
  background: #ecf0f1;
  margin-bottom: 4px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.nav-links-mobile {
  display: none;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
  .burger {
    display: flex;
  }
  .nav-links-mobile {
    display: flex;
    flex-direction: column;
    background: rgba(44, 62, 80, 0.95);
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 1rem 0;
  }
  .nav-links-mobile li {
    margin: 1rem 0;
    text-align: center;
  }
  .nav-links-mobile li a {
    font-size: 1.5rem;
  }
}

.burger span.active:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger span.active:nth-child(2) {
  opacity: 0;
}

.burger span.active:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.logo-img {
  border-radius: 1rem;
  zoom: 1.5;
}

</style>
