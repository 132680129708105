import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ApplyView from '@/views/ApplyView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/apply',
    name: 'apply',
    component: ApplyView,
  },
  {
    path: '/news',
    name: 'news',
    component: NotFoundView,
  },
  {
    path: '/beta-tester',
    name: 'betaTester',
    component: () => import('@/views/BetaTesterView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
