<template>
  <div class="apply-page">
    <div class="header">
      <div class="titles">
        <div class="text-wrapper">
          <img class="img-join-team" src="@/assets/apply.png" alt="Join Team Badge" />
          <div class="text">
            <h1 class="title">WE WANT YOU!</h1>
            <p class="slogan">
              Our Minecraft server is growing, and we’re looking for passionate
              and dedicated team members to help us enhance the player experience.
              As our community expands, we need talented individuals to fill key
              roles that will contribute to the server’s success. Whether you’re
              interested in moderating, building, developing, or managing the
              community, there’s a place for you on our team.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="roles-section">
      <transition-group :class="['roles-grid', { selected: selectedRole }]"
                        name="card-transition" tag="div">
        <div
          v-for="role in roles"
          :key="role.title"
          :class="['role-card', { selected: selectedRole && selectedRole.title === role.title }]"
          @click="selectRole(role)"
          @keydown.left="selectRole(role)"
        >
          <Card class="info-card">
            <template #title>
              <h3>{{ role.title }}</h3>
            </template>
            <template #content>
              <p>{{ role.description }}</p>
              <div v-if="selectedRole && selectedRole.title === role.title"
                   class="expanded-content" @click.stop>
                <p>{{ role.fullDescription }}</p>
                <form @submit.prevent="submitApplication(role.title)">
                  <div class="form-field">
                    <label for="name">Name</label>
                    <InputText id="name" v-model="formData.name" required />
                  </div>
                  <div class="form-field">
                    <label for="minecraftUsername">Minecraft Username</label>
                    <InputText id="minecraftUsername"
                               v-model="formData.minecraftUsername" required />
                  </div>
                  <div class="form-field">
                    <label for="reason">Apply Text</label>
                    <InputText id="reason" v-model="formData.reason" required />
                  </div>
                  <Button label="Submit Application" type="submit" class="submit-button" />
                </form>
                <Button label="Back" class="back-button" @click.stop="selectedRole = null" />
              </div>
            </template>
          </Card>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';

const toast = useToast();

const roles = [
  {
    title: 'Moderator',
    description: 'Ensure the server remains a safe and welcoming space.',
    fullDescription:
      'As a Moderator, you will monitor player interactions, address issues, and uphold community standards to maintain a friendly environment.',
  },
  {
    title: 'Builder',
    description: 'Craft incredible structures that inspire creativity.',
    fullDescription:
      'As a Builder, you will design and construct various buildings and landscapes, enhancing the visual appeal of our server.',
  },
  {
    title: 'Developer',
    description: 'Introduce new plugins and features.',
    fullDescription:
      'As a Developer, you will work on custom plugins, optimize server performance, and implement new gameplay features.',
  },
  {
    title: 'Community Manager',
    description: 'Organize events and manage our social platforms.',
    fullDescription:
      'As a Community Manager, you will engage with players, coordinate events, and handle communications across our platforms.',
  },
];

const selectedRole = ref(null);

const formData = ref({
  name: '',
  reason: '',
  minecraftUsername: '',
});

const selectRole = (role) => {
  if (selectedRole.value && selectedRole.value.title === role.title) {
    selectedRole.value = null;
  } else {
    selectedRole.value = role;
  }
};

const submitApplication = (roleTitle) => {
  console.log(`Application submitted for ${roleTitle}:`, formData.value);
  toast.add({
    severity: 'success',
    summary: 'Success',
    detail: `Thanks for applying as a ${roleTitle}`,
    life: 3000,
  });
};
</script>

<style scoped>
@font-face {
  font-family: 'rosemero';
  src: url('@/assets/fonts/ROSEMERO.ttf') format('truetype');
}

.apply-page {
  background: linear-gradient(135deg, #2c3e50 0%, #bdc3c7 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  min-height: 100vh;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
  position: relative;
}

.titles {
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.text-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.text {
  position: relative;
  width: 50%;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 2rem;
  box-sizing: border-box;
  z-index: 2;
}

.img-join-team {
  position: absolute;
  top: -125px;
  left: 20%;
  width: 200px;
  transform: rotate(-10deg);
  z-index: 1;
}

.title {
  font-family: rosemero, sans-serif;
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 1rem;
}

.slogan {
  font-family: rosemero, sans-serif;
  font-size: 1.2rem;
  color: #ecf0f1;
  margin-bottom: 2rem;
}

.roles-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  max-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
}

.roles-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 100%;
}

.role-card {
  cursor: pointer;
  animation: fadeInUp 0.8s ease forwards;
  transition: all 0.3s ease;
}

.role-card.selected {
  grid-column: 1 / -1;
  grid-row: 1;
}

.roles-grid.selected .role-card:not(.selected) {
  grid-column: span 1;
  grid-row: 2;
}

@media (min-width: 768px) {
  .roles-grid.selected {
    grid-template-columns: repeat(3, 1fr);
  }

  .roles-grid.selected .role-card:not(.selected) {
    grid-column: span 1;
  }
}

@media (max-width: 767px) {
  .roles-grid {
    grid-template-columns: 1fr;
  }

  .role-card.selected {
    grid-column: 1;
  }

  .roles-grid.selected .role-card:not(.selected) {
    grid-column: 1;
  }
}

.info-card {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  color: #2c3e50;
  border-radius: 10px;
  overflow: hidden;
}

.role-card:not(.selected) .info-card:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.expanded-content {
  margin-top: 1rem;
  animation: expand 0.5s ease forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes expand {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 1000px;
  }
}

.form-field {
  margin-bottom: 1rem;
}

.form-field label {
  display: block;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-field input {
  width: 100%;
}

.submit-button {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
}

.back-button {
  margin-top: 1rem;
}

.card-transition-enter-active,
.card-transition-leave-active {
  transition: all 0.5s ease;
}

.card-transition-enter-from,
.card-transition-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

@media (max-width: 992px) {
  .roles-grid {
    grid-template-columns: 1fr;
  }

  .roles-grid.selected {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .text {
    width: 80%;
  }

  .title {
    font-size: 2rem;
  }

  .slogan {
    font-size: 1rem;
  }

  .img-join-team {
    width: 150px;
    left: -10%;
    top: -80px;
  }
}
</style>
